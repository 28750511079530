import React, { useState, useEffect } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
// components
import Layout from "../components/layout";
import SEO from "../components/seo";
import FlexCard from "../components/flexcard";
import DrivingSchoolCard from "../components/drivingschoolcard";
import HomeVideoBackground from "../components/homevideobackground";
import Track from "../components/track";
import settings from "../../settings";

// images
const poster = `${settings.IMAGES_BASE_URL}/images/home/poster_home.webp`;
const poster1200 = `${settings.IMAGES_BASE_URL}/images/home/poster_home-1200.webp`;
const poster800 = `${settings.IMAGES_BASE_URL}/images/home/poster_home-800.webp`;
const poster400 = `${settings.IMAGES_BASE_URL}/images/home/poster_home-400.webp`;

const teenschool = `${settings.IMAGES_BASE_URL}/images/home/bmw-teen-school.jpg`;
const teenschool1 = `${settings.IMAGES_BASE_URL}/images/home/bmw-teen-school-400.webp`;
const teenschool2 = `${settings.IMAGES_BASE_URL}/images/home/bmw-teen-school-800.webp`;
const teenschool3 = `${settings.IMAGES_BASE_URL}/images/home/bmw-teen-school-1200.webp`;
const driverschool = `${settings.IMAGES_BASE_URL}/images/home/bmw_driver_school.jpg`;
const driverschool1 = `${settings.IMAGES_BASE_URL}/images/home/bmw_driver_school-400.webp`;
const driverschool2 = `${settings.IMAGES_BASE_URL}/images/home/bmw_driver_school-800.webp`;
const driverschool3 = `${settings.IMAGES_BASE_URL}/images/home/bmw_driver_school-1200.webp`;
const mschool = `${settings.IMAGES_BASE_URL}/images/home/bmw_m_school.jpg`;
const mschool1 = `${settings.IMAGES_BASE_URL}/images/home/bmw_m_school-400.webp`;
const mschool2 = `${settings.IMAGES_BASE_URL}/images/home/bmw_m_school-800.webp`;
const mschool3 = `${settings.IMAGES_BASE_URL}/images/home/bmw_m_school-1200.webp`;
const homeVideo = `${settings.IMAGES_BASE_URL}/videos/home-video-opening-2022.mp4`;
const pgaWestBanner = `${settings.IMAGES_BASE_URL}/images/home/pga-west-home-banner.jpg`;
const pgaWestBanner1 = `${settings.IMAGES_BASE_URL}/images/home/pga-west-home-banner-400.webp`;
const pgaWestBanner2 = `${settings.IMAGES_BASE_URL}/images/home/pga-west-home-banner-800.webp`;
const pgaWestBanner3 = `${settings.IMAGES_BASE_URL}/images/home/pga-west-home-banner-1200.webp`;
const mMixedRealityBanner = `${settings.IMAGES_BASE_URL}/images/home/m-mixed-reality-home-banner.jpg`;
const mMixedRealityBanner1 = `${settings.IMAGES_BASE_URL}/images/home/m-mixed-reality-home-banner-400.webp`;
const mMixedRealityBanner2 = `${settings.IMAGES_BASE_URL}/images/home/m-mixed-reality-home-banner-800.webp`;
const mMixedRealityBanner3 = `${settings.IMAGES_BASE_URL}/images/home/m-mixed-reality-home-banner-1200.webp`;
const creditCard = `${settings.IMAGES_BASE_URL}/images/home/bmw-cc-home.jpg`;
const creditCard1 = `${settings.IMAGES_BASE_URL}/images/home/bmw-cc-home-400.webp`;
const creditCard2 = `${settings.IMAGES_BASE_URL}/images/home/bmw-cc-home-800.webp`;
const creditCard3 = `${settings.IMAGES_BASE_URL}/images/home/bmw-cc-home-1200.webp`;
const indy1Banner = `${settings.IMAGES_BASE_URL}/images/home/indy-banner-01.jpg`;
const indy1Banner1 = `${settings.IMAGES_BASE_URL}/images/home/indy-banner-01-400.webp`;
const indy1Banner2 = `${settings.IMAGES_BASE_URL}/images/home/indy-banner-01-800.webp`;
const indy1Banner3 = `${settings.IMAGES_BASE_URL}/images/home/indy-banner-01-1200.webp`;
const indy2Banner = `${settings.IMAGES_BASE_URL}/images/home/indy-banner-02.jpg`;
const indy2Banner1 = `${settings.IMAGES_BASE_URL}/images/home/indy-banner-02-400.webp`;
const indy2Banner2 = `${settings.IMAGES_BASE_URL}/images/home/indy-banner-02-800.webp`;
const indy2Banner3 = `${settings.IMAGES_BASE_URL}/images/home/indy-banner-02-1200.webp`;

const IndexPage = () => {
  const [currentCard, setCurrentCard] = React.useState(null);

  React.useEffect(() => {
    const today = new Date();
    const showIndy1Banner = 
      today >= new Date(2025,0,1) && 
      today < new Date(2025,1,1);
    const showIndy2Banner = 
      today >= new Date(2025,2,1) && 
      today < new Date(2025,3,1);
    const mMixed = 
      today >= new Date(2025,1,1) && 
      today < new Date(2025,2,1);

    if (showIndy1Banner) {
      setCurrentCard(getIndy1Card());
    } else if (mMixed) {
      setCurrentCard(getmMixedCard());
    } else if (showIndy2Banner) {
      setCurrentCard(getIndy2Card());
    } else {
      setCurrentCard(getIndy1Card());
    }
  }, []);

  const getIndy1Card = () => {
    return (
      <>
        <FlexCard
          rowType="row"
          image={indy1Banner}
          imageAlt="READY TO ROLL"
          header="READY TO ROLL"
          subheader="INDY REGISTRATION IS NOW OPEN"
          text="Registration for both half-day and full-day events at The Indianapolis Motor Speedway are now open. Seats fill fast, so book yours today and ensure your place at this historic track."
          btnText="Book Half-Day"
          btnStyle="btn-blue"
          route="/indy-halfday"
          btnTwoText="Book Full-Day"
          routeTwo="/indy-fullday"
          srcSet={indy1Banner1 + " 400w, "+ indy1Banner2+" 800w, " + indy1Banner3+" 1200w"  }
          sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
        >
        </FlexCard>
      </>
    )
  } 

  const getIndy2Card = () => {
    return (
      <>
        <FlexCard
          rowType="row"
          image={indy2Banner}
          imageAlt="IT’S LIGHTS OUT AND AWAY WE GO"
          header="IT’S LIGHTS OUT AND AWAY WE GO"
          subheader="INDY REGISTRATION IS NOW OPEN"
          text="Registration for both half-day and full-day events at The Indianapolis Motor Speedway are now open. Seats fill fast, so book yours today and ensure your place at this historic track."
          btnText="Book Half-Day"
          btnStyle="btn-blue"
          route="/indy-halfday"
          btnTwoText="Book Full-Day"
          routeTwo="/indy-fullday"
          srcSet={indy2Banner1 + " 400w, "+ indy2Banner2+" 800w, " + indy2Banner3+" 1200w"  }
          sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
        >
        </FlexCard>
      </>
    )
  } 

  const getmMixedCard = () => {
    return (
      <>
        <FlexCard
          rowType="row"
          image={mMixedRealityBanner}
          imageAlt="WELCOME TO THE VIRTUAL REAL WORLD"
          header="WELCOME TO THE VIRTUAL REAL WORLD"
          subheader="M MIXED REALITY IS HERE"
          text="Hop into a BMW M car, slip on a headset, and drive it through a virtual track. This isn’t fantasy, and this isn’t reality. It’s both."
          btnText="Learn More"
          btnStyle="btn-blue"
          route="/experiences"
          srcSet={mMixedRealityBanner1 + " 400w, "+ mMixedRealityBanner2+" 800w, " + mMixedRealityBanner3+" 1200w"  }
          sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
        >
          <p>
            M Mixed Reality is led by professional driving instructors on a closed course, with customized vehicle technology specific to this experience.
          </p>
        </FlexCard>
      </>
    )
  }

  const secondCard = () => {
    return (
      <FlexCard
        rowType="row-reverse"
        image={creditCard}
        imageAlt="REWARDS FOR THE DRIVEN"
        header="REWARDS FOR THE DRIVEN"
        subheader="SAVE ON CLASSES WITH THE BMW CARD"
        srcSet={creditCard1 + " 400w, "+ creditCard2+" 800w, " + creditCard3+" 1200w"  }
        sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
        text="Accelerate your rewards with the BMW Card – exclusively for BMW drivers. Redeem for unlimited cash back toward your BMW Financial Services monthly payment or travel. Plus, you’ll save when you book a class with us."
        btnText="Learn more about the BMW card"
        btnStyle="btn-blue"
        externalURL="https://www.mybmwcreditcard.com/offer50"
      />
    )
  }


  return (
    <Layout>
      <SEO title="BMW Performance Driving School" description="Learn total car control behind the wheel of a BMW at the BMW Performance Driving School. Multiple class offerings and challenges await in one of our three locations." />
      <div className="homepage">
        <HomeVideoBackground
          url={homeVideo}
          poster={poster}
          srcSet={`${poster400} 400w, ${poster800} 800w, ${poster1200} 1200w, ${poster} 1920w`}
          sizes="100vw"
          hideMobileHeader={true}
          topLeftChildren={
            <div className="text_left">
              <div>DRIVE ON THE EDGE OF PHYSICS.</div>
            </div>
          }
        />
        <section className="performance-center">
          <div className="container">
            <div className="drivingschool__header">
              <h1>THE BMW PERFORMANCE DRIVING SCHOOL</h1>
              <p>
                An open track, the Ultimate Driving Machine, and you in the driver’s seat. <br/>
                Prepare for a BMW driving experience like no other.
              </p>
            </div>
          </div>
          {currentCard}
          {secondCard()}
          <FlexCard
            rowType="row"
            image={pgaWestBanner}
            imageAlt="SPEED TIME GOES WITH TEE TIME."
            header="SPEED TIME GOES WITH TEE TIME."
            subheader="PGA WEST DISCOUNT"
            srcSet={pgaWestBanner1 + " 400w, "+ pgaWestBanner2+" 800w, " + pgaWestBanner3+" 1200w"  }
            sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
            text="Book a round of golf at the exclusive PGA WEST for a discounted rate when you book a class at our Thermal location. Be on the lookout for a link in your confirmation email for more details."
          />
        </section>
        <Track variant="white" />
        <section className="drivingschool">
          <div className="container">
            <div className="drivingschool__header">
              <h2>BMW DRIVING SCHOOLS</h2>
            </div>
          </div>
          <DrivingSchoolCard
            rowType="row"
            image={teenschool}
            imageAlt="BMW Teen School 01"
            header="BMW TEEN SCHOOL"
            subheader="UNDERSTANDING THE BASICS"
            text="Leave your perception of driver’s education class at the door: this isn’t about parallel parking. Teens will learn total car control at the limit on our closed track, making them much more alert and aware. LOLs are encouraged."
            learnUrl="/teenschool"
            learnBtnStyle="btn-bordered"
            bookBtnStyle="btn-blue"
            bookUrl="https://pds.eventsbmw.com/?brand=BMW&class=BMW_One_Day_Teen_School"
            srcSet={teenschool1 + " 400w, "+ teenschool2+" 800w, " + teenschool3+" 1200w"  }
            sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
          />
          <DrivingSchoolCard
            rowType="row"
            image={driverschool}
            imageAlt="BMW Driver's School"
            header="BMW DRIVER’S SCHOOL"
            subheader="A THRILLING BMW DRIVING EXPERIENCE"
            text="Let’s bring you up to speed on the best ways to handle a BMW’s advanced capabilities with classes that focus on car control, proper vision, brake points and more. If your heart rate just went up, this is the class for you."
            learnUrl="/driverschool"
            learnBtnStyle="btn-bordered"
            bookBtnStyle="btn-blue"
            bookUrl="https://pds.eventsbmw.com/?brand=BMW&class=BMW_One_Day_School"
            srcSet={driverschool1 + " 400w, "+ driverschool2+" 800w, " + driverschool3+" 1200w"  }
            sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
          />
          <DrivingSchoolCard
            rowType="row"
            image={mschool}
            imageAlt="BMW M School"
            header="BMW M SCHOOL"
            subheader="IT’S PRACTICALLY A BMW RACE SCHOOL"
            text="Unleash the full potential of our M vehicles as you push them through high-speed stability, control and drifting exercises. Prepare for the ultimate drive."
            learnUrl="/mschool"
            learnBtnStyle="btn-bordered"
            bookBtnStyle="btn-blue"
            bookUrl="https://pds.eventsbmw.com/?brand=M&class=One_Day_M_School"
            srcSet={mschool1 + " 400w, "+ mschool2+" 800w, " + mschool3+" 1200w"  }
            sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
          />
        </section>
      </div>
    </Layout>
  );
}

export default IndexPage;
